import React, { useEffect, useState } from 'react';
 
function LearnMoreContent ({item, data}){
    const bg_color = data.color ?? '#842e14';

    useEffect(() => {
        
    }, []);

    function displaAmountTarget(){
        let item = []

        if(Object.keys(data.target_amount).length > 0){
            item = Object.entries(data.target_amount).map(([index, data]) => {
                let amount = data.amount ?? 0;
                if(typeof amount === 'string'){
                    amount = Number(amount)
                }
                amount = amount.toLocaleString("en-US");

                console.log(data)

                return <tr>
                            <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-blue w-100"  style={{backgroundColor: bg_color}}>&#8369;{amount}</span></h1></td>
                            <td><h5>{data.description}</h5></td>
                        </tr>
            })
        }

        return <table className='table table-borderles text-start mb-3'>{item}</table>     
    }

    return (
        <> 
        <div className="card border-0 lm-container">
            <div className="card-header text-light" style={{backgroundColor: bg_color}}>
                <h1>{data.title}</h1>
            </div>
            <div className="card-body">
                <p className='text-start'><b>Areas:</b> {data.area}</p>

                {displaAmountTarget()}

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div>

        {item === 'childhood-rescue' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-blue">
                <h1>CHILDHOOD RESCUE</h1>
            </div>
            <div className="card-body">
                {/* <table className='table table-borderles mb-4 text-start'>
                        <tr>
                            <td width="33.33%"><b>Areas:</b><br/>Marawi and Cotabato City</td>
                            <td width="33.33%"><b>Direct Beneficiaries:</b> 6,599</td>
                            <td width="33.33%"><b>Indirect beneficiaries:</b> 13,538</td>
                        </tr>
                </table> */}
                <p className='text-start'><b>Areas:</b> Marawi and Cotabato City</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-blue w-100">P50,000</span></h1></td>
                        <td><h5>Safe Play Space</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-blue w-100">P70,000</span></h1></td>
                        <td><h5>Bridge Construction</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-blue w-100">P100,000</span></h1></td>
                        <td><h5>Health Center Expansion</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}

        {item === 'start-up' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-light-green">
                <h1>START UP</h1>
            </div>
            <div className="card-body">
                {/* <table className='table table-borderles mb-4 text-start'>
                        <tr>
                            <td width="33.33%"><b>Areas:</b> Nationwide</td>
                            <td width="33.33%"><b>Direct Beneficiaries:</b> 62,507</td>
                            <td width="33.33%"><b>Indirect beneficiaries:</b> 292,115</td>
                        </tr>
                </table> */}
                <p className='text-start'><b>Areas:</b> Nationwide</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-light-green w-100">P50,000</span></h1></td>
                        <td><h5>Joy2Cart - Resell their products!</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-light-green w-100">P70,000</span></h1></td>
                        <td><h5>Livelihood Starting Capital (Equipment)</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-light-green w-100">P100,000</span></h1></td>
                        <td><h5>Micro-Financing (ComSCA) Summit</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}

        {item === 'study-up' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-orange">
                <h1>STUDY UP</h1>
            </div>
            <div className="card-body">
                {/* <table className='table table-borderles mb-4 text-start'>
                        <tr>
                            <td width="33.33%"><b>Areas:</b> Nationwide</td>
                            <td width="33.33%"><b>Direct Beneficiaries:</b> 62,507</td>
                            <td width="33.33%"><b>Indirect beneficiaries:</b> 292,115</td>
                        </tr>
                </table> */}
                <p className='text-start'><b>Areas:</b> Nationwide</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-orange w-100">P50,000</span></h1></td>
                        <td><h5>Volunteering Activities</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-orange w-100">P70,000</span></h1></td>
                        <td><h5>New Normal Education Needs</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-orange w-100">P100,000</span></h1></td>
                        <td><h5>School Facilities</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}

        {item === 'wash-up' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-purple">
                <h1>WASH UP</h1>
            </div>
            <div className="card-body">
                <p className='text-start'><b>Areas:</b> Pangasinan, Camarines Norte, Bukidnon, Sultan Kudarat, Batangas</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-purple w-100">P50,000</span></h1></td>
                        <td><h5>Combat Malnutrition Program</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-purple w-100">P70,000</span></h1></td>
                        <td><h5>Go Baby Go! Parenting Training</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-purple w-100">P100,000</span></h1></td>
                        <td><h5>Handwashing Facilities</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}

        {item === 'stand-up' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-green">
                <h1>STAND UP</h1>
            </div>
            <div className="card-body">
                {/* <table className='table table-borderles mb-4 text-start'>
                        <tr>
                            <td width="33.33%"><b>Areas:</b> Nationwide</td>
                            <td width="33.33%"><b>Direct Beneficiaries:</b> 62,507</td>
                            <td width="33.33%"><b>Indirect beneficiaries:</b> 292,115</td>
                        </tr>
                </table> */}
                <p className='text-start'><b>Areas:</b> Nationwide</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-green w-100">P50,000</span></h1></td>
                        <td><h5>School-based Training</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-green w-100">P70,000</span></h1></td>
                        <td><h5>Community-based Training</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-green w-100">P100,000</span></h1></td>
                        <td><h5>Child Helpline and Daycare Facility</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}

        {item === 'dapat-ready' ? 
        <div className="card border-0 lm-container">
            <div className="card-header bg-maroon">
                <h1>DAPAT READY!</h1>
            </div>
            <div className="card-body">
                {/* <table className='table table-borderles mb-4 text-start'>
                        <tr>
                            <td width="33.33%"><b>Areas:</b> Nationwide</td>
                            <td width="33.33%"><b>Direct Beneficiaries:</b> 62,507</td>
                            <td width="33.33%"><b>Indirect beneficiaries:</b> 292,115</td>
                        </tr>
                </table> */}
                <p className='text-start'><b>Areas:</b> Nationwide</p>

                <table className='table table-borderles text-start mb-3'>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-maroon w-100">P50,000</span></h1></td>
                        <td><h5>Pre-positioned Relief Items</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-maroon w-100">P70,000</span></h1></td>
                        <td><h5>Disaster Response Training</h5></td>
                    </tr>
                    <tr>
                        <td width='150'><h1 className='p-0 pe-3'><span className="badge bg-maroon w-100">P100,000</span></h1></td>
                        <td><h5>School Covered Court Improvement</h5></td>
                    </tr>
                </table>

                <h5 className='fw-normal text-start'>You have the power to make this a reality!</h5>
            </div>
        </div> : ''}
        </>
    )
}
 
export default LearnMoreContent;