import React from 'react';
import { Routes, Route } from "react-router-dom";

/** Component Pages Here */
import HomePage from '../components/pages/HomePage';
import FaqPage from '../components/pages/FaqPage';
import OtherPage from '../components/pages/OtherPage';
import ThankYou from '../components/pages/ThankYou';

import TrackTracePage from '../components/pages/TrackTracePage';

function MainRoutes(){
    return (
        <Routes>
            <Route 
                exact path="/" 
                element={<HomePage />} 
            />

            <Route 
                exact path="track" 
            element={<TrackTracePage />} 
            />

            <Route 
                exact path="thankyou" 
                element={<ThankYou />} 
            />

            <Route 
                exact path="others" 
                element={<OtherPage />} 
            />

            {/* <Route 
                path="*" 
                element={<Page404 />} 
            /> */}
        </Routes>
    )
}

export default MainRoutes;
