import React, { useEffect } from 'react';
 
function OtherPage (){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <>
            <div id="main-content" className="container-fluid p-0">
            <h1>Other Page</h1>
            </div>
        </>
    )
}
 
export default OtherPage;